// Generated by Framer (3def70c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./lAE9Fv1Sm-0.js";

const cycleOrder = ["WLiQtlQYA", "d18vFB4Y_", "KOGSFpDR3", "CkMuHadOU"];

const serializationHash = "framer-sLY5R"

const variantClassNames = {CkMuHadOU: "framer-v-17s7v6w", d18vFB4Y_: "framer-v-9flu56", KOGSFpDR3: "framer-v-18wzgjl", WLiQtlQYA: "framer-v-zag5r4"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {D4PSPoXBX: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Service image component 2": "d18vFB4Y_", "Service image component 3": "KOGSFpDR3", "Service image component 4": "CkMuHadOU", "Service image component": "WLiQtlQYA"}

const getProps = ({height, id, image, image2, image3, image4, width, ...props}) => { return {...props, O4ulRBE7u: image2 ?? props.O4ulRBE7u ?? {src: "https://framerusercontent.com/images/C44YbV3zn5MiOE6MrV4RJSX0.png?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/C44YbV3zn5MiOE6MrV4RJSX0.png?scale-down-to=512 512w,https://framerusercontent.com/images/C44YbV3zn5MiOE6MrV4RJSX0.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/C44YbV3zn5MiOE6MrV4RJSX0.png 1120w"}, tRbAekRPQ: image ?? props.tRbAekRPQ ?? {src: "https://framerusercontent.com/images/m5JtYB4nxKXIl9LfLgJMwT1doAY.jpg?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/m5JtYB4nxKXIl9LfLgJMwT1doAY.jpg?scale-down-to=1024 682w,https://framerusercontent.com/images/m5JtYB4nxKXIl9LfLgJMwT1doAY.jpg?scale-down-to=2048 1365w,https://framerusercontent.com/images/m5JtYB4nxKXIl9LfLgJMwT1doAY.jpg 2731w"}, Ut5nCMqur: image4 ?? props.Ut5nCMqur ?? {src: "https://framerusercontent.com/images/5qQanXcw297YfGHbpSI0iGz0CVI.jpg?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/5qQanXcw297YfGHbpSI0iGz0CVI.jpg?scale-down-to=1024 682w,https://framerusercontent.com/images/5qQanXcw297YfGHbpSI0iGz0CVI.jpg?scale-down-to=2048 1365w,https://framerusercontent.com/images/5qQanXcw297YfGHbpSI0iGz0CVI.jpg?scale-down-to=4096 2730w,https://framerusercontent.com/images/5qQanXcw297YfGHbpSI0iGz0CVI.jpg 3648w"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "WLiQtlQYA", VPF2Ej9d7: image3 ?? props.VPF2Ej9d7 ?? {src: "https://framerusercontent.com/images/gzvgGNhB94MRz0RjT212JStxgdg.png?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/gzvgGNhB94MRz0RjT212JStxgdg.png?scale-down-to=512 512w,https://framerusercontent.com/images/gzvgGNhB94MRz0RjT212JStxgdg.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/gzvgGNhB94MRz0RjT212JStxgdg.png 1920w"}} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};image2?: {src: string; srcSet?: string};image3?: {src: string; srcSet?: string};image4?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, tRbAekRPQ, O4ulRBE7u, VPF2Ej9d7, Ut5nCMqur, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "WLiQtlQYA", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapu9eeld = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
await delay(() => setVariant("d18vFB4Y_"), 150)
})

const onTapak19uo = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
await delay(() => setVariant("KOGSFpDR3"), 150)
})

const onTaptfjeo1 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
await delay(() => setVariant("CkMuHadOU"), 150)
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", intrinsicHeight: 640, intrinsicWidth: 1120, pixelHeight: 640, pixelWidth: 1120, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(tRbAekRPQ)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-zag5r4", className, classNames)} data-framer-name={"Service image component"} data-highlight layoutDependency={layoutDependency} layoutId={"WLiQtlQYA"} onTap={onTapu9eeld} ref={ref ?? ref1} style={{borderBottomLeftRadius: 15, borderBottomRightRadius: 15, borderTopLeftRadius: 15, borderTopRightRadius: 15, opacity: 0.55, ...style}} {...addPropertyOverrides({CkMuHadOU: {"data-framer-name": "Service image component 4", background: {alt: "", fit: "fill", intrinsicHeight: 640, intrinsicWidth: 1120, pixelHeight: 640, pixelWidth: 1120, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(Ut5nCMqur)}}, d18vFB4Y_: {"data-framer-name": "Service image component 2", background: {alt: "", fit: "fill", intrinsicHeight: 640, intrinsicWidth: 1120, pixelHeight: 640, pixelWidth: 1120, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(O4ulRBE7u)}, onTap: onTapak19uo}, KOGSFpDR3: {"data-framer-name": "Service image component 3", background: {alt: "", fit: "fill", intrinsicHeight: 640, intrinsicWidth: 1120, pixelHeight: 640, pixelWidth: 1120, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(VPF2Ej9d7)}, onTap: onTaptfjeo1}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-sLY5R.framer-pxld17, .framer-sLY5R .framer-pxld17 { display: block; }", ".framer-sLY5R.framer-zag5r4 { cursor: pointer; height: 334px; overflow: visible; position: relative; width: 525px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 334
 * @framerIntrinsicWidth 525
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"d18vFB4Y_":{"layout":["fixed","fixed"]},"KOGSFpDR3":{"layout":["fixed","fixed"]},"CkMuHadOU":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"tRbAekRPQ":"image","O4ulRBE7u":"image2","VPF2Ej9d7":"image3","Ut5nCMqur":"image4"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerlAE9Fv1Sm: React.ComponentType<Props> = withCSS(Component, css, "framer-sLY5R") as typeof Component;
export default FramerlAE9Fv1Sm;

FramerlAE9Fv1Sm.displayName = "service image";

FramerlAE9Fv1Sm.defaultProps = {height: 334, width: 525};

addPropertyControls(FramerlAE9Fv1Sm, {variant: {options: ["WLiQtlQYA", "d18vFB4Y_", "KOGSFpDR3", "CkMuHadOU"], optionTitles: ["Service image component", "Service image component 2", "Service image component 3", "Service image component 4"], title: "Variant", type: ControlType.Enum}, tRbAekRPQ: {__defaultAssetReference: "data:framer/asset-reference,m5JtYB4nxKXIl9LfLgJMwT1doAY.jpg?originalFilename=photo-1604076913837-52ab5629fba9%3Fcrop%3Dentropy%26cs%3Dsrgb%26fm%3Djpg%26ixid%3DM3wxMzc5NjJ8MHwxfHNlYXJjaHwxNXx8cGF0dGVybiUyMGdyYWRpYW50fGVufDB8fHx8MTcxODg4MTEzOXww%26ixlib%3Drb-4.0.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}, O4ulRBE7u: {__defaultAssetReference: "data:framer/asset-reference,C44YbV3zn5MiOE6MrV4RJSX0.png?originalFilename=3d+photo+for+blog.png&preferredSize=auto", title: "Image 2", type: ControlType.ResponsiveImage}, VPF2Ej9d7: {__defaultAssetReference: "data:framer/asset-reference,gzvgGNhB94MRz0RjT212JStxgdg.png?originalFilename=03.png&preferredSize=auto", title: "Image 3", type: ControlType.ResponsiveImage}, Ut5nCMqur: {__defaultAssetReference: "data:framer/asset-reference,5qQanXcw297YfGHbpSI0iGz0CVI.jpg?originalFilename=photo-1521133573892-e44906baee46%3Fcrop%3Dentropy%26cs%3Dsrgb%26fm%3Djpg%26ixid%3DM3wxMzc5NjJ8MHwxfHNlYXJjaHwxfHxjcnlzdGFsJTIwcGF0dGVybnxlbnwwfHx8fDE3MTg4ODExMDF8MA%26ixlib%3Drb-4.0.jpg&preferredSize=auto", title: "Image 4", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerlAE9Fv1Sm, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})